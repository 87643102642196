import React from 'react';
import './Products.css';

const productsData = [
  {
    id: 1,
    image: '/assets/prod1.jpg',
    name: 'Product 1',
    description: 'This is the description of Product 1.',
  },
  {
    id: 2,
    image: '/assets/prod2.jpg',
    name: 'Product 2',
    description: 'This is the description of Product 2.',
  },
  {
    id: 3,
    image: '/assets/prod3.jpg',
    name: 'Product 3',
    description: 'This is the description of Product 2.',
  },
  {
    id: 4,
    image: '/assets/prod4.jpg',
    name: 'Product 4',
    description: 'This is the description of Product 2.',
  },
  {
    id: 5,
    image: '/assets/prod5.jpg',
    name: 'Product 5',
    description: 'This is the description of Product 2.',
  },
  {
    id: 6,
    image: '/assets/prod6.jpg',
    name: 'Product 6',
    description: 'This is the description of Product 2.',
  },
  {
    id: 7,
    image: '/assets/prod7.jpg',
    name: 'Product 7',
    description: 'This is the description of Product 2.',
  },
  {
    id: 8,
    image: '/assets/prod8.jpg',
    name: 'Product 8',
    description: 'This is the description of Product 2.',
  },
  {
    id: 9,
    image: '/assets/prod9.jpg',
    name: 'Product 9',
    description: 'This is the description of Product 2.',
  },
  // {
  //   id: 10,
  //   image: '/assets/prod10.jpg',
  //   name: 'Product 10',
  //   description: 'This is the description of Product 2.',
  // },
  // Add more product data as needed
];

const Products = () => {
    return (
        <section id="products" className="section">
          <div className="container">
            <h2 class="text-center" style={{ fontWeight:'bold' }}>Some of our Sample Products</h2>
            <div className="products-wrapper">
              {productsData.map((product, index) => (
                <div
                  className={`product ${index % 2 === 0 ? 'image-left' : 'image-right'}`}
                  key={product.id}
                >
                  <div className="product-image">
                    <img src={product.image} alt={product.name} />
                  </div>
                  <div className="product-description">
                    <h3 class="font-weight-bold">{product.name}</h3>
                    <p>To buy this product click on the below button</p>
                    <a href="https://wa.me/9836166366?text=Welcome%20to%20Mrittika%20Creation,%20Click%20on%20above%20to%20Chat%20and%20Order.Or%20Call%20on%20the%20given%20Number" target="_blank" rel="noopener noreferrer" className="buy-button">
                  <img src="/assets/shopping.png" alt="Shopping Cart" />
                  Buy Now
                </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      );
    };
    
export default Products;
