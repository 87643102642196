import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#CE8042' }}>
      <div className="container">
        <a className="navbar-brand" href="#">
        <img src="/assets/logo.jpg" width="auto" height="40" class="d-inline-block align-top" alt="" />
          </a>
          <h2 style={{ fontWeight:'bold' }}>Mrittika Creation</h2>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="#home" style={{ fontWeight: 'bold' }}>Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#products" style={{ fontWeight: 'bold' }}>Products</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about" style={{ fontWeight: 'bold' }}>About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact" style={{ fontWeight: 'bold' }}>Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
