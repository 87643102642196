import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './About.css';

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 }); // Initialize AOS library
  }, []);

  return (
    <section id="about" className="section">
      <div className="container">
        <h2 className="section-title text-center">About Our Company</h2>
        <img src="/assets/largelogo.jpg" alt="logo" class="logo-image"></img>
        <div className="about-content">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome to Mrittika Creation, your destination for exquisite pottery creations. At our shop, we specialize in crafting unique and beautiful pieces made with love and care from the finest clay. Our passion for pottery shines through in every creation we make.

Mrittika Creation is a haven for all pottery enthusiasts, offering a wide range of handcrafted items that showcase the artistry and elegance of working with mud. From elegant vases and delicate ceramic figurines to functional kitchenware and decorative pieces, we have something to suit every taste and style.

We take great pride in our craftsmanship, ensuring that each piece is meticulously shaped, glazed, and fired to perfection. Our skilled artisans pour their creativity and expertise into every creation, resulting in stunning works of art that capture the essence of beauty and craftsmanship.

Whether you're looking to enhance your home decor, find a unique gift for a loved one, or simply indulge in the pleasure of owning a handmade piece, Mrittika Creation is your go-to pottery shop. We believe in the power of handmade art to bring joy, warmth, and a touch of nature to your life.

Browse through our collection and experience the magic of pottery firsthand. We invite you to explore the world of Mrittika Creation and discover the perfect piece that speaks to your heart. Thank you for supporting local artisans and embracing the beauty of handmade pottery
          </p>
        </div>
        <div className="team-section" data-aos="fade-up">
          {/* <h3 className="team-heading">Core Management Team</h3> */}
          <div className="team-cards">
            <div className="team-card">
            
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src="/assets/ripan.jpeg" alt="Team Member 1" className="team-member-img" />
              <h4 className="team-member-name">Ripan Chandra Paul</h4>
              <p className="team-member-position">Owner</p>
              </a>
            </div>
            {/* <div className="team-card">
              <img src="/assets/gorai.jpg" alt="Team Member 2" className="team-member-img" />
              <h4 className="team-member-name">Amar Gorai</h4>
              <p className="team-member-position">Co-founder and Infra Head</p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
