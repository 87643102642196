import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
// import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';
import Products from './components/Products';

const App = () => {
  return (
    <div>
      <Navbar />
      <Home />
      <Products />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
