import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="logo-container">
            {/* Insert your logo image here */}
            <img src="/assets/logo.jpg" alt="Logo" className="logo" />
          </div>
          <div className="social-icons">
            <a href="https://hi-in.facebook.com/people/KhusuMasha/100086957572327/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.youtube.com/channel/UCmSkk3-0Jfmd7-YVny5TgLA" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
        <p className="footer-text">© 2023 KhusuMasha. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
