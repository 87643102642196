import React from 'react';
import { Carousel } from 'react-bootstrap';
import './home.css';

const Home = () => {
  return (
    <section id="home" className="section">
      <div className="container-fluid p-0">
        <Carousel interval={5000} /* Adjust the interval duration as needed */>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/assets/pottery.jpg"
              alt="Slide 1"
            />
            <Carousel.Caption>
              <h3>Unleash your creativity at Mrittika Creation,<br /> where our skilled artisans bring clay to life on the wheel.</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/assets/card2.jpg"
              alt="Slide 2"
            />
            <Carousel.Caption>
              <h3>Discover the artistry of handcrafted pottery at Mrittika Creation,<br /> where vibrant hues and unique designs come together</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/assets/card3.jpg"
              alt="Slide 3"
            />
            <Carousel.Caption>
              <h3>Experience the meticulous craftsmanship at Mrittika Creation, <br />where each brushstroke creates stunning patterns on our ceramic masterpieces</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/assets/card4.jpg"
              alt="Slide 4"
            />
            <Carousel.Caption>
              <h3>Browse through our collection and experience the magic of pottery firsthand.<br /> We invite you to explore the world of Mrittika Creation and discover the perfect piece that speaks to your heart.<br /> Thank you for supporting local artisans and embracing the beauty of handmade pottery</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};

export default Home;
